import React, { useState } from 'react';
import { useParams, usePathname } from 'next/navigation';
import { PAGE_LOCALES } from '@src/typescriptGlobals/constants';
import Globe from '@src/components/Icons/Globe';
import useDropdownStaggeredFadeAnimation from '@src/hooks/useDropdownStaggeredFadeAnimation';
import isEmpty from '@src/utils/isEmpty';
import NextLink from '../NextLink';
import { LanguageTypes } from './TopNavigation.types';
import { RootStateTypes, useTypedAppSelector } from '@src/redux/store/store.types';
const ListItem = ({
  className
}: {
  className: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const params = useParams<{
    locale: string;
  }>();
  const [notificationBubble, setNotificationBubble] = useState(false);
  const pathname = usePathname(); // This returns the full path like "/en/some-page"
  const {
    locale: localeRXS
  } = useTypedAppSelector((state: RootStateTypes) => state.geoRXS);
  React.useEffect(() => {
    if (PAGE_LOCALES.includes(localeRXS!) && localeRXS !== 'en') {
      if (localeRXS === params?.locale) return;
      const showTimeout = setTimeout(() => setNotificationBubble(true), 3000);
      const hideTimeout = setTimeout(() => setNotificationBubble(false), 13000);
      return () => {
        clearTimeout(showTimeout);
        clearTimeout(hideTimeout);
      };
    }
  }, [localeRXS]);
  const {
    listRef,
    containerRef
  } = useDropdownStaggeredFadeAnimation({
    isOpen
  });
  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);
  const languages: LanguageTypes = {
    en: 'English',
    fr: 'Français',
    es: 'Español',
    it: 'Italiano',
    de: 'Deutch'
  };
  const availableIn: LanguageTypes = {
    en: 'Available in',
    fr: 'Disponible en',
    es: 'Disponible en',
    it: 'Disponibile in',
    de: 'Verfügbar in'
  };

  // Remove locale from pathname if it's present
  const cleanPathname = (() => {
    if (!params.locale) return pathname;
    const pathSegments = pathname.split('/').filter(Boolean);
    if (pathSegments.length > 0 && PAGE_LOCALES.includes(pathSegments[0])) {
      pathSegments.shift(); // Remove the locale from the path
    }
    return '/' + pathSegments.join('/');
  })();
  return <li className={`cursor-pointer ${className}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} data-sentry-component="ListItem" data-sentry-source-file="LanguageDropdown.tsx">
      {/* Dropdown trigger */}
      <Globe className={`transition-colors duration-300 ease-in-out hover:text-primary ${notificationBubble ? 'text-primary' : ''}`} data-sentry-element="Globe" data-sentry-source-file="LanguageDropdown.tsx" />
      {notificationBubble && <>
          <div className='w-0 h-0 absolute left-[0.3rem] top-[5.7rem] z-[1000] border-b-[1.2rem] border-l-[0.65rem] border-r-[0.65rem] border-t-[0.65rem] border-b-[#F2F2F2] border-l-[transparent] border-r-[transparent] border-t-[transparent]'></div>
          <div className={`absolute left-1/2 top-full h-[5.65rem] w-[13rem] -translate-x-1/2 transform overflow-hidden border border-[#F2F2F2] bg-background-secondary text-center transition-opacity duration-300 2xl:!text-[1.6rem]`}>
            <p className='mb-[0.5rem] px-[1rem] pt-[1rem] text-[1.6rem]'>
              {availableIn?.[localeRXS as keyof LanguageTypes]}
            </p>

            <NextLink locale={localeRXS ?? 'en'} href={cleanPathname} className='px-[1rem] text-[1.6rem] font-[400] text-primary'>
              {languages?.[localeRXS as keyof LanguageTypes]}
            </NextLink>
          </div>
        </>}

      {/* Dropdown content */}
      {!isEmpty({
      value: PAGE_LOCALES
    }) && <div ref={!notificationBubble ? containerRef : undefined} className={`pointer-events-none invisible absolute left-1/2 top-full z-10 w-[13rem] -translate-x-1/2 transform overflow-hidden border border-border bg-background-secondary opacity-0 transition-opacity duration-300 2xl:!text-[1.6rem]`}>
          <div ref={!notificationBubble ? listRef : undefined} className='flex flex-col py-[1rem] pl-[1.25rem] text-left'>
            {PAGE_LOCALES?.map((locale, index) => <NextLink key={`${index}-page-locale`} locale={locale} href={cleanPathname} className={`cursor-pointer text-[1.6rem] font-[400] text-body-copy--dark transition-colors duration-300 ease-in-out hover:font-[500] hover:text-primary [&:not(:last-child)]:pb-[1rem] ${params?.locale === locale ? 'font-[500] text-primary' : ''}`}>
                {languages?.[locale as keyof LanguageTypes]}
              </NextLink>)}
          </div>
        </div>}
    </li>;
};
export default ListItem;