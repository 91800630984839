'use client';

import React from 'react';
import { useRouter } from 'next/navigation';
import isFullUrl from '@src/utils/isFullUrl';
import { SideNavPropTypes } from './TopNavigation.types';
import SideNavItem from './SideNavItem';
const SideNavigation: React.FC<SideNavPropTypes> = ({
  list,
  onToggleMobileNav,
  isHamburger
}) => {
  const router = useRouter();
  const onNavigate = ({
    slug,
    languageCode
  }: {
    slug: string;
    languageCode: string;
  }) => {
    return () => {
      if (isFullUrl({
        url: slug
      })) {
        window.open(slug, '_blank');
      } else {
        if (slug?.startsWith('#')) return;
        const path = languageCode === 'en' ? slug : `/${languageCode}${slug}`;
        router.push(path);
        onToggleMobileNav();
      }
    };
  };
  return <ul className={`fixed bottom-[0] left-[0] z-[10000000000] mx-auto mt-[8rem] flex h-[calc(100*var(--vh)-6.4rem)] w-[100vw] max-w-[160rem] flex-col overflow-y-auto bg-background-secondary px-[2.4rem] py-[2.4rem] pt-[1rem] transition-all duration-300 ease-in-out md:px-[6.3rem] lg:px-[6.4rem] xl:hidden xl:px-[12.8rem] xl:locale-fr:flex 3xl:px-[4rem] 3xl:locale-fr:hidden 4xl:px-[0]
     ${isHamburger ? 'visible opacity-100' : 'invisible opacity-0'}
    `} data-sentry-component="SideNavigation" data-sentry-source-file="SideNavigation.tsx">
      <SideNavItem item={list?.find(page => page?.slug === '/sports')!} onNavigate={onNavigate} data-sentry-element="SideNavItem" data-sentry-source-file="SideNavigation.tsx" />
      <SideNavItem item={list?.find(page => page?.slug === '/organizations')!} onNavigate={onNavigate} data-sentry-element="SideNavItem" data-sentry-source-file="SideNavigation.tsx" />
      <SideNavItem item={list?.find(page => page?.slug === '/applications')!} onNavigate={onNavigate} data-sentry-element="SideNavItem" data-sentry-source-file="SideNavigation.tsx" />
      <SideNavItem item={list?.find(page => page?.slug === '/products')!} onNavigate={onNavigate} data-sentry-element="SideNavItem" data-sentry-source-file="SideNavigation.tsx" />
      <SideNavItem item={list?.find(page => page?.slug === '#resources')!} onNavigate={onNavigate} data-sentry-element="SideNavItem" data-sentry-source-file="SideNavigation.tsx" />
      <SideNavItem item={list?.find(page => page?.slug === '#support')!} onNavigate={onNavigate} data-sentry-element="SideNavItem" data-sentry-source-file="SideNavigation.tsx" />
      <SideNavItem className='!border-[0rem]' item={list?.find(page => page?.slug === '/contact')!} onNavigate={onNavigate} data-sentry-element="SideNavItem" data-sentry-source-file="SideNavigation.tsx" />
    </ul>;
};
export default SideNavigation;