import Dot from '@src/components/Icons/Dot';
import Image from 'next/image';
import NextLink from '../NextLink';
import isEmpty from '@src/utils/isEmpty';
import dateFormatter from '@src/utils/dateFormatter';
import { HitItem } from './SearchResult.type';
const getPathFromUrl = ({
  url
}: {
  url: string;
}) => {
  try {
    const urlObject = new URL(url); // Parse the URL
    const path = urlObject.pathname; // Get the pathname

    // Split the path into parts
    const parts = path.split('/').filter(Boolean); // Remove empty parts

    // Check if the first segment is likely a locale (e.g., two letters)
    if (parts[0].length === 2 && /^[a-zA-Z]{2}$/.test(parts[0])) {
      parts.shift(); // Remove the first segment if it's a locale
    }
    return '/' + parts.join('/'); // Rebuild and return the path without the locale
  } catch (error) {
    return null; // Return null if the URL is invalid
  }
};
const SearchResult = ({
  locale,
  item,
  components,
  globalTextBlock
}: HitItem) => {
  const slug = getPathFromUrl({
    url: item?.pageUrl
  }) ?? '';
  return <NextLink locale={locale?.toLowerCase()} href={slug} className='flex items-center hover:bg-background-grey-light' data-sentry-element="NextLink" data-sentry-component="SearchResult" data-sentry-source-file="SearchResult.tsx">
      <div className='m-[1rem] hidden md:block'>
        <Image src={item?.thumbnail} alt='' className='rounded-[0.4rem]' width={90} height={47} data-sentry-element="Image" data-sentry-source-file="SearchResult.tsx" />
      </div>
      <div className='flex flex-1 flex-col gap-[0.6rem] rounded-[0.5rem] p-[1rem] text-body-copy--dark'>
        <div className='font-[500] leading-[2.2rem]'>
          <components.Highlight hit={item} attribute='pageName' data-sentry-element="unknown" data-sentry-source-file="SearchResult.tsx" />
        </div>

        <div className='flex flex-col gap-[0.5rem] text-[1.2rem] sm:flex-row md:items-center'>
          <div className='flex gap-[0.5rem]'>
            <div className='font-[300]'>{item?.category !== '' ? item?.category : 'Pages'}</div>
            {!isEmpty({
            value: item?.createdDate
          }) ? <>
                <Dot className='mt-[0.5rem] text-body-copy--dark' />
                <time dateTime={item?.createdDate} className='font-[300]'>
                  {dateFormatter(item?.createdDate)}
                </time>
              </> : ''}
          </div>

          {item?.createdDate !== item?.updatedDate && !isEmpty({
          value: item?.updatedDate
        }) && <div className='font-[300] capitalize sm:ml-auto'>
              {globalTextBlock?.items?.[0]?.lastUpdated}{' '}
              <time dateTime={item?.updatedDate} className='font-[300]'>
                {dateFormatter(item?.updatedDate)}
              </time>
            </div>}
        </div>

        <div className='flex gap-[0.5rem]'>
          {item?.languages?.map((lang: string) => {
          const isActive = locale === lang?.toLowerCase();
          return <NextLink href={slug} key={`${lang}-translations`} locale={lang?.toLowerCase()} className={`rounded-[0.2rem] p-[0.2rem] text-[1.2rem] font-[200] text-[#25282A] transition-colors duration-300 ease-in-out hover:bg-[#ffbc80] ${isActive ? 'bg-primary font-[500] text-[#fff]' : 'bg-[#DDDDDD] hover:bg-[#ccc]'}`}>
                {lang}
              </NextLink>;
        })}
        </div>

        <div className='font-[300] leading-[2.2rem]'>
          <components.Snippet hit={item} attribute='content' data-sentry-element="unknown" data-sentry-source-file="SearchResult.tsx" />
        </div>
        <div className='!hidden'>
          <components.Highlight hit={item} attribute='content' data-sentry-element="unknown" data-sentry-source-file="SearchResult.tsx" />
        </div>
      </div>
    </NextLink>;
};
export default SearchResult;