'use client';

import React, { useEffect } from 'react';
import '../../../app/hubspot-form.css';
import { useParams } from 'next/navigation';
interface HubspotFormProps {
  portalId: string;
  formId: string;
  formTarget?: string;
  redirectUrl?: string;
  className?: string;
  region?: string;
  onFormReady?: () => void;
  onFormSubmitted?: () => void;
}
const HubspotForm: React.FC<HubspotFormProps> = ({
  portalId,
  formId,
  redirectUrl,
  formTarget = 'form',
  className = '',
  region,
  onFormReady
}) => {
  const {
    locale
  } = useParams<{
    locale: 'fr' | 'es';
  }>();
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (window.hbspt) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        window.hbspt.forms.create({
          portalId: portalId,
          formId: formId,
          target: `#${formTarget}`,
          redirectUrl: `/${locale}${redirectUrl}`,
          region: region,
          onFormReady: () => {
            // Delay to ensure the form elements are fully rendered
            setTimeout(() => {
              const formContainer = document.getElementById(formTarget);
              if (formContainer) {
                // Define translations for labels and placeholders
                const translations: Record<'fr' | 'es', Record<string, string>> = {
                  fr: {
                    'First name*': 'Prénom*',
                    'Last name*': 'Nom*',
                    'Email address*': 'Adresse e-mail*',
                    'Email*': 'E-mail*',
                    'Phone number': 'Numéro de téléphone',
                    'Region*': 'Région*',
                    Message: 'Message',
                    'Organizaton*': 'Organisation',
                    'Country*': 'Pays',
                    Mobile: 'Téléphone',
                    'Interested in': 'Intéressé par',
                    'All Products': 'Tous les produits',
                    Other: 'Autres',
                    'Your message': 'Votre message',
                    'Enter your first name': 'Saisissez votre prénom',
                    'Enter your last name': 'Saisissez votre nom de famille',
                    'Enter your email address': 'Saisissez votre adresse électronique',
                    'Enter your organization': 'Entrez votre organisation',
                    'Enter your mobile number': 'Saisissez votre numéro de téléphone portable',
                    'Select your country': 'Veuillez sélectionner',
                    'Please complete this required field.': 'Veuillez remplir ce champ obligatoire.',
                    Submit: 'Envoyer'
                  },
                  es: {
                    'First name*': 'Nombre*',
                    'Last name*': 'Apellido*',
                    'Email address*': 'Correo Electrónico*',
                    'Email*': 'Correo Electrónico*',
                    'Phone number': 'Número de teléfono*',
                    'Region*': 'Región*',
                    Message: 'Mensaje*',
                    'Organizaton*': 'Organización',
                    'Country*': 'País',
                    Mobile: 'Teléfono',
                    'Interested in': 'Interesado en',
                    'All Products': 'Todos los productos',
                    Other: 'Otros',
                    'Your message': 'Su mensaje',
                    'Enter your first name': 'Introduzca su nombre',
                    'Enter your last name': 'Introduzca su apellido',
                    'Enter your email address': 'Introduzca su dirección de correo electrónico',
                    'Enter your organization': 'Introduzca su organización',
                    'Enter your mobile number': 'Introduzca su número de móvil',
                    'Select your country': ' Por favor seleccione',
                    'Please complete this required field.': 'Rellene este campo obligatorio.',
                    Submit: 'Enviar'
                  }
                };
                const currentTranslations = translations[locale] || {};

                // Helper function to apply translations
                const applyTranslations = () => {
                  // Update all label elements (including error messages)
                  formContainer.querySelectorAll('label').forEach(label => {
                    const span = label.querySelector('span');
                    if (span) {
                      const originalText = span.textContent?.trim();
                      if (originalText && currentTranslations[originalText]) {
                        span.textContent = currentTranslations[originalText];
                      }
                    } else {
                      const originalText = label.textContent?.trim();
                      if (originalText && currentTranslations[originalText]) {
                        label.textContent = currentTranslations[originalText];
                      }
                    }
                  });

                  // Update error messages if needed
                  formContainer.querySelectorAll('.hs-error-msg').forEach(errorMsg => {
                    const text = errorMsg.textContent?.trim();
                    if (text && currentTranslations[text]) {
                      errorMsg.textContent = currentTranslations[text];
                    }
                  });

                  // Update input placeholders
                  formContainer.querySelectorAll('input').forEach(input => {
                    const originalPlaceholder = input.getAttribute('placeholder')?.trim();
                    if (originalPlaceholder && currentTranslations[originalPlaceholder]) {
                      input.setAttribute('placeholder', currentTranslations[originalPlaceholder]);
                    }
                  });

                  // Update text for select options
                  formContainer.querySelectorAll('select option').forEach(option => {
                    const optionText = option.textContent?.trim();
                    if (optionText && currentTranslations[optionText]) {
                      option.textContent = currentTranslations[optionText];
                    }
                  });

                  // Update the submit button text
                  formContainer.querySelectorAll("input[type='submit']").forEach(btn => {
                    const originalValue = btn.getAttribute('value')?.trim();
                    if (originalValue && currentTranslations[originalValue]) {
                      btn.setAttribute('value', currentTranslations[originalValue]);
                    }
                  });
                };

                // Initial translation update
                applyTranslations();

                // Observe dynamic changes, including attribute changes to the "value" attribute
                const observer = new MutationObserver(mutations => {
                  mutations.forEach(mutation => {
                    if (mutation.type === 'attributes' || mutation.addedNodes.length > 0) {
                      applyTranslations();
                    }
                  });
                });
                observer.observe(formContainer, {
                  childList: true,
                  subtree: true,
                  attributes: true,
                  attributeFilter: ['value']
                });

                // Reveal the form once translations are applied
                formContainer.style.opacity = '1';
              }

              // Execute additional callback if provided
              if (onFormReady) {
                onFormReady();
              }
            }, 0);
          }
        });
      }
    });

    // Cleanup on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, [portalId, formId, redirectUrl, locale, formTarget, region, onFormReady]);

  // Start with the form hidden
  return <div id={formTarget} className={`${className} valdHsFormBlock`} style={{
    opacity: 0
  }} data-sentry-component="HubspotForm" data-sentry-source-file="HubspotForm.tsx"></div>;
};
export default HubspotForm;