import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { ActionTypes } from '..';

dayjs.extend(utc);
dayjs.extend(timezone);

export const setCountry = () => {
  const userTimezone = dayjs.tz.guess(); // Get user's timezone
  const countryFromTimezone = userTimezone.split('/')[0]; // Extract country part
  const countryCode = countryFromTimezone;

  return {
    type: ActionTypes.SET_COUNTRY,
    payload: countryCode,
  };
};

export const setLocale = () => {
  const locale = navigator.language || navigator.languages[0]; // e.g., "fr-FR", "en-US"
  const [language] = locale.split('-'); // Extracts ["fr", "FR"]

  return {
    type: ActionTypes.SET_LOCALE,
    payload: language,
  };
};
