'use client';

import React from 'react';
import { usePathname } from 'next/navigation';
import Button from '../Button';
import isEmpty from '@src/utils/isEmpty';
import { SideNavItemPropTypes } from './TopNavigation.types';
const SideNavItem: React.FC<SideNavItemPropTypes> = ({
  className,
  item,
  onNavigate
}) => {
  const pathname = usePathname();
  return <li key={`${item?.sys?.id}-nav-item`} className='cursor-pointer' data-sentry-component="SideNavItem" data-sentry-source-file="SideNavItem.tsx">
      <div className={`block text-left md:hover:bg-background-secondary ${className} ${!isEmpty({
      value: item?.subPagesCollection?.items
    }) || item?.slug === 'https://security.valdperformance.com/login' ? 'border-b-[0.1rem] border-border' : ''}`}>
        <div onClick={onNavigate({
        slug: item?.slug,
        languageCode: item?.languageCode?.toLowerCase()!
      })} className={`${!isEmpty({
        value: item?.subPagesCollection?.items
      }) ? 'mb-[-1rem]' : ''} flex items-center justify-between py-[1.6rem]`}>
          <span className={`text-[1.6rem] font-[400] ${item?.slug === '/contact' ? 'lg:!text-primary' : 'text-body-copy--dark'} ${pathname.includes(item?.slug) ? 'font-[500]' : ''}`}>
            {item?.pageName}
          </span>
        </div>

        {/* Dropdown content */}
        {!isEmpty({
        value: item?.subPagesCollection?.items
      }) && <ul className={`${item?.slug === '/products' ? 'md:grid md:grid-cols-2 ' : ''}`}>
            {item?.subPagesCollection?.items?.map(item => {
          return <li key={`${item?.sys?.id}-sidenav-item`} className='cursor-pointer p-[1rem] font-[400] text-body-copy--dark'>
                  <div onClick={onNavigate({
              slug: item?.slug,
              languageCode: item?.languageCode?.toLowerCase()
            })}>
                    <span className={`block text-[1.6rem] ${pathname?.includes(item?.slug) ? 'font-[500]' : ''}`}>
                      {item?.pageName}
                    </span>
                    {item?.description && <span className='mt-[0.5rem] block text-[1.4rem] font-[300] leading-[2.2rem]'>
                        {item?.description}
                      </span>}
                  </div>
                </li>;
        })}
            {item?.slug === '/products' && <Button tertiary href='/products' align='justify-start' btnText='All Products' containerClassName={`pl-[1.5rem] py-[1rem] ${item?.pageName}`} />}
          </ul>}
      </div>
    </li>;
};
export default SideNavItem;