'use client';

import React from 'react';
import { AnnouncementBarResponseTypes } from '@src/graphql/queries.types';
import { GQL_FETCH_ANNOUNCEMENT_ENTRIES } from '@src/graphql/queries';
import { IS_PREVIEW, PAGE_LOCALES } from '@src/typescriptGlobals/constants';
import RenderBodyCopy from '../RenderBodyCopy';
import useEbookSlideAnimate from '@src/hooks/useEbookSlideAnimate';
import fetchAPI from '@src/utils/fetchAPI';
import { AnnouncementBarPropsTypes, AnnouncementTypes } from './AnnouncementBar.types';
const AnnouncementBar: React.FC<AnnouncementBarPropsTypes> = ({
  params: {
    locale
  }
}) => {
  const ebookSlideRef = useEbookSlideAnimate();
  const [eBooks, setEBooks] = React.useState<AnnouncementTypes>();
  React.useEffect(() => {
    fetchAPI<AnnouncementBarResponseTypes>({
      query: GQL_FETCH_ANNOUNCEMENT_ENTRIES?.loc?.source?.body ?? '',
      variables: {
        locale: PAGE_LOCALES.includes(locale) ? locale?.toUpperCase() : 'EN',
        preview: IS_PREVIEW
      }
    }).then(res => setEBooks(res.eBooks?.items?.[0]));
  }, []);
  const multipleEbook = eBooks?.topicCollection?.items?.length! > 1;
  return <div ref={ebookSlideRef} id='announcement-bar' className={`text-white invisible absolute top-[7.4rem] z-[100] h-[5rem] w-full items-center justify-center gap-[0.4rem] overflow-hidden bg-primary py-[1rem] text-center text-[1.6rem] font-[500] text-body-copy--light shadow-lg sm:flex sm:flex sm:h-[auto] [&_*_p]:text-left [&_*_p]:!leading-[1.6rem]`} data-sentry-component="AnnouncementBar" data-sentry-source-file="AnnouncementBar.tsx">
      {eBooks?.title && <p className={`self-center text-center lg:text-[1.8rem] ${multipleEbook ? 'sm:ml-[-24rem]' : ''}`}>
          {eBooks?.title}
        </p>}

      <div className={`relative flex items-center text-center`}>
        {eBooks?.topicCollection?.items?.map(eBook => {
        return <RenderBodyCopy key={eBook?.sys?.id} bodyCopy={eBook?.bodyCopy?.json?.content} className={`top-[1.4rem] mt-[-0.2rem] flex items-center justify-center text-[1.6rem] text-body-copy--light underline hover:text-[#ECEAE8] sm:left-[unset] sm:top-[unset] sm:justify-start [&>p>a]:!text-body-copy--light ${multipleEbook ? 'invisible absolute w-[100%] sm:w-[45rem]' : 'mt-[1rem] w-[100%] sm:mt-[unset] md:w-[unset] [&>p]:!w-[100%] [&>p]:!text-center md:[&>p]:!w-[unset]'}`} />;
      })}
      </div>
    </div>;
};
export default AnnouncementBar;