import React, { useState } from 'react';
import { useParams } from 'next/navigation';
import { PAGE_LOCALES } from '@src/typescriptGlobals/constants';
import useDropdownStaggeredFadeAnimation from '@src/hooks/useDropdownStaggeredFadeAnimation';
import NextLink from '../NextLink';
import Button from '../Button';
import Container from '../Container';
import isEmpty from '@src/utils/isEmpty';
import { ProductsListItemPropTypes } from './TopNavigation.types';
const ProductsListItem = ({
  className,
  page,
  pathname,
  globalTextBlock
}: ProductsListItemPropTypes) => {
  const params = useParams<{
    locale: string;
  }>();
  const [isOpen, setIsOpen] = useState(false);
  const {
    listRef,
    containerRef
  } = useDropdownStaggeredFadeAnimation({
    isOpen
  });
  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);
  React.useEffect(() => {
    setIsOpen(false);
  }, [pathname]);
  return <li className={`group hidden h-[100%] cursor-pointer items-center xl:flex xl:locale-fr:hidden 3xl:locale-fr:flex ${className}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} data-sentry-component="ProductsListItem" data-sentry-source-file="ProductsListItem.tsx">
      {/* Dropdown trigger */}
      <NextLink locale={PAGE_LOCALES.includes(params?.locale) ? params?.locale : 'en'} href={page?.slug} className={`font-[400] text-body-copy--dark transition-colors duration-300 ease-in-out group-hover:text-primary ${pathname.includes(page?.slug) ? 'font-[500]' : ''}`} data-sentry-element="NextLink" data-sentry-source-file="ProductsListItem.tsx">
        {page?.pageName}
      </NextLink>

      {/* Dropdown content */}
      {!isEmpty({
      value: page?.subPagesCollection?.items
    }) && <div ref={containerRef} className='pointer-events-none invisible top-[100%] z-[100001] w-full border-[0.1rem] border-t border-border bg-background-secondary opacity-0 transition-all duration-300 ease-in-out xl:fixed xl:left-[0] xl:top-[7.4rem]'>
          <Container containerRef={listRef} HtmlTag='div' className='!mb-[0] grid gap-y-[2.2rem] !px-[1.5rem] py-[2.4rem] md:grid-cols-2 md:gap-[2rem] xl:mx-auto xl:max-w-[92rem] 2xl:max-w-[115rem] 2xl:gap-x-[5rem] 2xl:!pl-[30rem]'>
            {page?.subPagesCollection?.items?.map(sub => {
          return <NextLink locale={PAGE_LOCALES.includes(params?.locale) ? params?.locale : 'en'} key={`${sub?.sys?.id}-sub-menu`} href={sub?.slug} className={`rounded-lg [&>span:not(:last-child)]:hover:font-[500] [&>span:not(:last-child)]:hover:text-primary ${pathname.includes(sub?.slug) ? '[&>span:not(:last-child)]:font-[500]' : ''}`}>
                  <span className={`text-header-copy mb-[0.5rem] block text-[1.6rem] font-[400] text-body-copy--dark transition-colors duration-300 ease-in-out`}>
                    {sub?.pageName}
                  </span>
                  <span className='block text-[1.4rem] font-[300] leading-[2.2rem] text-body-copy'>
                    {sub?.description}
                  </span>
                </NextLink>;
        })}
            <Button tertiary href='/products' align='justify-start' btnText={globalTextBlock?.items?.[0]?.allProducts} containerClassName='mb-[-0.4rem] !items-end hidden xl:flex !items-center' />
          </Container>
        </div>}
    </li>;
};
export default ProductsListItem;