'use client';

import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';
import { store } from '@src/redux/store/store';

/**
 * Providers component that wraps the application with ReduxProvider.
 * Add more providers here if needed.
 */
const Providers: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  return <>
      <ContentfulLivePreviewProvider locale='en-US' enableInspectorMode={true} enableLiveUpdates={true} debugMode targetOrigin='https://app.contentful.com' data-sentry-element="ContentfulLivePreviewProvider" data-sentry-source-file="Providers.tsx">
        <ReduxProvider store={store} data-sentry-element="ReduxProvider" data-sentry-source-file="Providers.tsx">{children}</ReduxProvider>
      </ContentfulLivePreviewProvider>
    </>;
};
export default Providers;